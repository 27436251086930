.faq-container {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
}

h1 {
	text-align: center;
	margin-bottom: 20px;
}

.faq-section {
	margin-bottom: 20px;
}

.faq-heading {
	font-size: 1.5rem;
	font-weight: bold;
	cursor: pointer;
	color: #333;
	border-bottom: 2px solid #ddd;
	padding: 10px;
}

.faq-list {
	padding-left: 0;
	list-style: none;
	margin: 0;
}

.faq-item {
	margin-top: 10px;
}

.faq-question {
	font-size: 1.2rem;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	background-color: #ffffff;
	border-radius: 5px;
	transition: background-color 0.3s ease;
}

.faq-answer {
	display: none;
	padding: 10px;
	border: 1px solid #ffffff;
	border-radius: 5px;
	background-color: #ffffff;
	margin-top: 10px;
	transition: max-height 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.faq-answer.active {
	display: block;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Shadow effect when answer is open */
}

.faq-question span {
	font-size: 1.5rem;
	transition: transform 0.3s ease; /* For smooth rotation */
}

.faq-question.active span {
	transform: rotate(180deg); /* Rotate arrow when clicked */
}

.faq-item:hover .faq-question {
	background-color: #ffffff; /* Light background on hover */
}
