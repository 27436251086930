/* navbar stylings  */
.pp-navbar {
	height: auto;
	width: 90%;
	padding: 19px 0px 19px 0px;

	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.pp-navbar-logo {
	width: 4.25rem;
}

.pp-navbar-logo img {
	width: 100%;
	height: 100%;
}

.pp-navbar-fonts {
	color: #000;
	font-family: "Sora", sans-serif;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.pp-navbar-fonts:hover {
	cursor: pointer;
}

.search-container {
	width: 20.6875rem;
	height: 3rem;
	border-radius: 2.1875rem;
	border: 1px solid #000;
	background: #fff;
	display: flex;
	align-items: center;
	gap: 12px;

	padding: 6px 10px 6px 10px;
}

.search-icon {
	width: 1.75rem;
	height: 1.75rem;
	flex-shrink: 0;
}

.search-input {
	width: 331px;
	border: none;

	color: rgba(16, 16, 16, 0.5);
	font-family: "Sora", sans-serif;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.search-input:focus {
	outline: none;
	border: none;
}

.navbar-login {
	display: flex;
	padding: 1rem 1.25rem;
	justify-content: center;
	align-items: center;
	gap: 0.67306rem;
	border-radius: 3.36538rem;
	background: #0f0705;
}

.navbar-login-text {
	color: #fff;
	font-family: "Sora", sans-serif;
	font-size: 1.07694rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.navbar-login:hover {
	cursor: pointer;
}

.navbar-mobile-popup {
	display: none;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.z-50 {
	z-index: 50;
}

/* mobile and versions below 1200px  */
@media (max-width: 1100px) {
	.pp-navbar-logo {
		display: none;
	}
	.navbar-fonts {
		display: none;
	}
	.search-container {
		display: none;
	}

	.navbar-login {
		display: none;
	}
	.navbar-mobile-popup {
		display: block;
		width: 1.875rem;
		height: 1.875rem;
		flex-shrink: 0;
		transform: scale(1, -1);
	}
}

.mobile {
	display: none;
}
/* Above are the styling for the navbar  */

.product-page-container {
	width: 90%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin: 3%;
}

.header-container {
	width: 90%;
	display: flex;
	gap: 0.81rem;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	align-items: flex-start;
	margin-top: 1rem;
}

.text-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.heading {
	color: #000;
	font-family: "DM Sans", sans-serif;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.description {
	color: rgba(0, 0, 0, 0.85);
	font-family: "DM Sans", sans-serif;
	font-size: 0.9375rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.filter-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.pp-search-container {
	width: 60%;
	height: 1.1625rem;
	border-radius: 0.3125rem;
	border: 0.761px solid rgba(0, 0, 0, 0.75);
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
}

.pp-search-icon {
	width: 0.9375rem;
	height: 0.9375rem;
}

.pp-search-input {
	border: none;
	width: 100%;
	color: rgba(16, 16, 16, 0.5);
	font-family: "Sora", sans-serif;
	font-size: 0.625rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.pp-search-input:focus {
	outline: none;
	border: none;
}

.filter-text {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.31rem;

	color: #d62b2b;
	font-family: "DM Sans";
	font-size: 0.925rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

/* product page line  */
.product-page-line {
	width: 120%;
	margin: 3%;
	margin-left: -10%;
	height: 0.0625rem;
	background: rgba(0, 0, 0, 0.5);
}

/* Filter Modal Styles */
.filter-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: none;
	justify-content: flex-end;
	align-items: stretch;
	z-index: 1000;
}

.filter-overlay.show {
	display: flex;
}

.modal-container {
	width: 300px;
	background: white;
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.close-button {
	cursor: pointer;
	font-size: 24px;
}

.category-container,
.price-container,
.discounts-container {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.category-heading,
.price-heading,
.discount-heading {
	font-family: "DM Sans", sans-serif;
	font-size: 1.125rem;
	font-weight: 600;
}

.radio-group {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.radio-item {
	display: flex;
	align-items: center;
	gap: 8px;
}

/* Custom checkbox styles */
.radio-item input[type="radio"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 16px;
	height: 16px;
	border: 1px solid #333;
	border-radius: 0; /* Makes it square */
	outline: none;
	cursor: pointer;
	position: relative;
	margin: 0;
}

.radio-item input[type="radio"]:checked {
	background-color: #0f0705;
	border-color: #0f0705;
}

.radio-item input[type="radio"]:checked::after {
	content: "✓";
	position: absolute;
	color: white;
	font-size: 12px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.radio-label {
	font-family: "DM Sans", sans-serif;
	font-size: 0.875rem;
	color: #333;
}

.price-range {
	display: flex;
	align-items: center;
	gap: 12px;
}

.price-input {
	width: 100px;
	padding: 8px;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.apply-button {
	margin-top: auto;
	padding: 12px;
	background: #0f0705;
	color: white;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	font-family: "Sora", sans-serif;
	font-weight: 500;
}

.apply-button:hover {
	background: #2c1810;
}

/* product pages */
.product-page-cards-container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 5%;
}

/* card stylings  */
.pp_card {
	width: 27%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0.2rem;
	box-sizing: border-box;
	gap: 0.2rem;
	margin-bottom: 2%;
}

.pp_image {
	width: 100%;
	height: 5rem;
	flex-shrink: 0;
	position: relative;
	overflow: hidden;
}

.pp-image-discount {
	position: absolute;
	right: 3%;
	top: 3%;
	flex-shrink: 0;
	border-radius: 0.125rem;
	background: #d62b2b;

	color: #fff;
	text-align: center;
	font-family: "Sora", sans-serif;
	font-size: 0.5rem;
	font-style: normal;
	font-weight: 400;
	padding: 2% 5% 2% 5%;
}

.pp_image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0.3125rem;
	background: lightgray 50% / cover no-repeat, #d9d9d9;
}

.pp-company {
	color: rgba(0, 0, 0, 0.95);
	font-family: "Sora", sans-serif;
	font-size: 0.625rem;
	font-style: normal;
	font-weight: 700;
	margin: 0;
	width: 100%;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.pp-type {
	color: #000;
	font-family: "Sora", sans-serif;
	font-size: 0.625rem;
	font-style: normal;
	font-weight: 400;
	margin: 0;
	width: 100%;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.pp-card-footer {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: auto;
}

.pp-price {
	color: #000;
	font-family: "Sora", sans-serif;
	font-size: 0.425rem;
	font-style: normal;
	font-weight: 400;
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.pp-prevPrice {
	position: relative;
	color: rgba(0, 0, 0, 0.65);
	font-family: "Sora", sans-serif;
	font-size: 0.4rem;
	font-style: normal;
	font-weight: 400;
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.pp-prevPrice-stroke {
	position: absolute;
	left: 0;
	top: 47%;
	width: 100%;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.65);
	transform: translateY(-50%);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.pp-discount {
	color: #d62b2b;
	font-family: "Sora", sans-serif;
	font-size: 0.6rem;
	font-style: normal;
	font-weight: 400;
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.pp-card-button {
	text-decoration: none;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.425rem;
	background: #882a2a;
	padding: 2%;

	color: #fff;
	font-family: "Sora", sans-serif;
	font-size: 0.5rem;
	font-style: normal;
	font-weight: 400;
}

.pp-card-button:hover {
	cursor: pointer;
}

@media (min-width: 750px) {
	.pp_card {
		width: 28%;
		margin-bottom: 2%;
		gap: 0.5rem;
	}

	.pp_image {
		height: 8rem;
	}

	.pp-image-discount {
		font-size: 0.6rem;
		padding: 3% 5% 3% 5%;
	}

	.pp-company {
		font-size: 0.825rem;
		margin: 0;
	}

	.pp-type {
		font-size: 0.825rem;
	}

	.pp-price {
		font-size: 0.725rem;
	}

	.pp-prevPrice {
		font-size: 0.7rem;
	}

	.pp-discount {
		font-size: 0.7rem;
	}

	.pp-card-button {
		font-size: 0.9rem;
	}
}

@media (min-width: 1000px) {
	.pp_card {
		width: 28%;
		margin-bottom: 2%;
		gap: 0.5rem;
	}

	.pp_image {
		height: 15rem;
	}

	.pp-image-discount {
		font-size: 0.8rem;
		padding: 3% 5% 3% 5%;
	}

	.pp-company {
		font-size: 1.125rem;
		margin: 0;
	}

	.pp-type {
		font-size: 1.125rem;
	}

	.pp-price {
		font-size: 0.925rem;
	}

	.pp-prevPrice {
		font-size: 0.9rem;
	}

	.pp-discount {
		font-size: 0.9rem;
	}

	.pp-card-button {
		font-size: 1.2rem;
		line-height: 1.775rem;
	}
}

@media (min-width: 1443px) {
	.mobile {
		display: flex;
	}
	.filter-container {
		display: none;
	}
	.product-page-line {
		display: none;
	}
	/* New styles for desktop layout */
	.product-page-container {
		min-height: 100vh;
		display: flex;
		flex-wrap: wrap;
		gap: 5rem;
		position: relative;
	}

	.header-container {
		width: 90%;
		margin-top: 1rem;
	}

	.heading {
		font-size: 2.25rem;
		font-weight: 700;
	}

	.description {
		font-size: 1.2375rem;
	}

	.filter-overlay {
		position: absolute;
		display: block !important; /* Force display */
		background: none;
		width: 23%;
		height: fit-content;
		margin-top: 0;
		top: 8rem;
		background: #fff;
		box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
	}

	.modal-container {
		width: 100%;
		height: 100%;
		padding: 0;
		background: none;
		position: sticky;
		top: 2rem;
	}

	.modal-header .close-button {
		display: none;
	}

	.product-page-cards-container {
		width: 70%;
		margin-left: auto;
		margin-top: 0;
	}

	/* Adjust card widths for the new layout */
	.pp_card {
		width: 28%;
		margin-bottom: 2%;
	}
	.pp_image {
		height: 20.5625rem;
	}
	.pp-image-discount {
		display: none;
	}
	.pp-company {
		color: black;
		font-family: "Archivo", sans-serif;
		font-size: 1.25rem;
		font-weight: 700;
	}
	.pp-type {
		color: #000;
		text-align: center;
		font-family: "Archivo", sans-serif;
		font-size: 1.25rem;
	}
	.pp-price {
		font-size: 1.25rem;
		font-weight: 500;
	}

	.pp-prevPrice {
		font-size: 1.25rem;
	}

	.pp-discount {
		border-radius: 0.5rem;
		background: #d62b2b;

		color: #fff;
		text-align: center;
		font-family: "Archivo", sans-serif;
		font-size: 1rem;
		font-weight: 500;
		padding: 1% 2% 1% 2%;
	}

	.pp-card-button {
		border-radius: 0.825rem;
		font-size: 1.3rem;
	}
}
