/* navbar stylings  */
.navbar {
	height: auto;
	width: 90%;
	padding: 19px 0px 19px 0px;

	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.navbar-logo {
	width: 4.25rem;
}

.navbar-logo img {
	width: 100%;
	height: 100%;
}

.navbar-fonts {
	color: #000;
	font-family: "Sora", sans-serif;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.navbar-fonts:hover {
	cursor: pointer;
}

.navbar-search-container {
	width: 20.6875rem;
	height: 3rem;
	border-radius: 2.1875rem;
	border: 1px solid #000;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;

	padding: 6px 10px 6px 10px;
}

.navbar-search-icon {
	width: 1.75rem;
	height: 1.75rem;
	flex-shrink: 0;
}

.navbar-search-input {
	width: 60%;
	border: none;

	color: rgba(16, 16, 16, 0.5);
	font-family: "Sora", sans-serif;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.navbar-search-input:focus {
	outline: none;
	border: none;
}

.navbar_search_button {
	padding: 0.5rem 0.8rem;
	border-radius: 2.1875rem;
	background: #0f0705;
	color: #fff;
	font-family: "Sora", sans-serif;
	font-size: 0.9rem;
	font-weight: 400;
	cursor: pointer;
	border: none;
	transition: background-color 0.2s ease;
}

.search_button:hover {
	background: #2a1c17;
}

.navbar-login {
	display: flex;
	padding: 1rem 1.25rem;
	justify-content: center;
	align-items: center;
	gap: 0.67306rem;
	border-radius: 3.36538rem;
	background: #0f0705;
}

.navbar-login-text {
	color: #fff;
	font-family: "Sora", sans-serif;
	font-size: 1.07694rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.navbar-login:hover {
	cursor: pointer;
}

.navbar-mobile-popup {
	display: none;
}

/* mobile and versions below 1200px  */
@media (max-width: 1100px) {
	.navbar-logo {
		width: 2.7375rem;
		height: 2.7375rem;
	}

	.navbar-search-container {
		width: 55%;
		height: 1.75rem;
		flex-shrink: 0;

		border-radius: 0.5rem;
		border: 0.5px solid #000;
		background: #fff;

		padding: 2px 10px 2px 10px;
	}

	.navbar-search-icon {
		background-color: black;
		color: white;
		width: 0.925rem;
		height: 0.925rem;
		flex-shrink: 0;
		border-radius: 50%;
		padding: 0.1rem;
	}

	.navbar-search-input {
		width: 60%;
		font-size: 0.5rem;
		font-weight: 400;
	}

	.navbar_search_button {
		padding: 0.25rem 0.5rem;
		font-size: 0.5rem;
		border-radius: 0.5rem;
	}

	.navbar-fonts {
		display: none;
	}
	.navbar-login {
		display: none;
	}
	.navbar-mobile-popup {
		display: block;
		width: 1.875rem;
		height: 1.875rem;
		flex-shrink: 0;
		transform: scale(1, -1);
	}
}

/* Sidebar Modal Styles */
.sidebar-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: none;
	z-index: 1000;
}

.sidebar-overlay.show {
	display: block;
}

.sidebar {
	position: fixed;
	top: 0;
	right: 0;
	width: 80%;
	max-width: 400px;
	height: 100%;
	background-color: white;
	padding: 20px;
	box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.sidebar-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.sidebar-logo {
	width: 2.9375rem;
	height: 2.9375rem;
	border-radius: 50%;
	background-color: #101010;
}

.close-icon {
	width: 1.875rem;
	height: 1.875rem;
	cursor: pointer;
}

.sidebar-search {
	width: 100%;
	height: 3rem;
	border-radius: 2.1875rem;
	border: 1px solid #000;
	background: #fff;
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 6px 10px;
}

.sidebar-nav {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.sidebar-nav-item {
	color: #000;
	font-family: "Sora", sans-serif;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.sidebar-login {
	display: flex;
	padding: 1rem 1.25rem;
	justify-content: center;
	align-items: center;
	gap: 0.67306rem;
	border-radius: 3.36538rem;
	background: #0f0705;
	margin-top: auto;
	cursor: pointer;
	margin-bottom: 2rem;
}

.sidebar-login-text {
	color: #fff;
	font-family: "Sora", sans-serif;
	font-size: 1.07694rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

/* Animation for sidebar */
@keyframes slideIn {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
}

.sidebar {
	animation: slideIn 0.3s ease-out;
}

/* Add these styles to your existing Navbar.css file */

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.top-full {
	top: 100%;
}

.mt-2 {
	margin-top: 0.5rem;
}

.bg-white {
	background-color: white;
}

.border {
	border-width: 1px;
}

.border-gray-200 {
	border-color: #e5e7eb;
}

.rounded-md {
	border-radius: 0.375rem;
}

.shadow-lg {
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.z-50 {
	z-index: 50;
}

.px-4 {
	padding-left: 1rem;
	padding-right: 1rem;
}

.py-2 {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.hover\:bg-gray-100:hover {
	background-color: #f3f4f6;
}

.cursor-pointer {
	cursor: pointer;
}

.flex {
	display: flex;
}

.items-center {
	align-items: center;
}

.w-full {
	width: 100%;
}

.ml-4 {
	margin-left: 1rem;
}
