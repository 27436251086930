.company-bar {
	width: 120%;
	height: 100%;
	display: flex;
	justify-content: center;
	background-color: black;
	align-items: center;
	flex-shrink: 0;
	margin-left: -10%;
}

.company-image-container {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	gap: 5%;
}

/* Specific images with responsive widths */
#amazon {
	width: 7%;
}

#myntra {
	width: 7%;
}

#meesho {
	width: 5%;
}

#flipkart {
	width: 7%;
}

#ajio {
	width: 7%;
}
