.card_wrapper{
    list-style-type: none;
    padding-left: 0px;
	display: flex;
	justify-content: flex-start;
    align-items: center;
	gap: 1rem;
	overflow-x: auto;
	flex-wrap: nowrap; 
	-webkit-overflow-scrolling: touch;
    width: 100%;
}
.image-container{
    position: relative;
}
.card_image{
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 6rem;
    width:10rem ;
}
.time-badge-image {
    
position: absolute;
  bottom: 6px; 
  left: 3px;   
  background-color: rgba(0, 0, 0, 0.7); 
  color: #fff;
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 12px;
  }
  .time-badge-imag-desktop{
    display: none;

  }
.card_title{
    margin: 0;
    font-size: 18px;
    color: #171A1F;
}
.card_description_container{
    height: auto;
}
.card_description-desktop{
    display: none;
}
.card_description{
    color: #171A1FBF;
    font-size: 12px;
    margin: 0;
    width: 100%;
    line-height: 1.5em;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
 
}
.author_container{
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 10px
    
}
.author_image{
    height: 25px;
    width: 25px;
    border-radius: 50px;
}
.written_by{
    font-size: 10px;
    margin: 0;
}
.author_name{
    font-size: 10px;
    margin: 0;
}
.writter_container{
    margin-left: 5px;
}
.card_wrapper-desktop{
    display: none;
}

@media screen and (min-width:900px) {
    .card_wrapper{
        display: none;
    }
    
    .card_wrapper-desktop{
        list-style-type: none;
        padding-left: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        overflow-x: auto;
        flex-wrap: nowrap;
        width: 100%;
        -webkit-overflow-scrolling: touch; /* Optional, can be removed */
    }


    .card_container-desktop{
        flex: none; /* Prevent shrinking */
        width: 235px; /* Ensure fixed width */
        border-radius: 2px;
        transition: transform 0.1s ease, box-shadow 0.1s ease;
        cursor: pointer; 
    }

    .card_container-desktop:hover{
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
        transform: translateY(-3px) !important;
        padding: 5px 10px 5px 10px;
        border-radius: 8px;
    
    }
    
    .image-container-desktop{
        width:100%;
    }
    .card_image-desktop{
        width: 100%;
        height: 130px;
        border-radius: 8px;
    }
    .time-badge-image{
        display: none;
    }
    .time-badge-imag-desktop{
        display: block;
        font-size: 14px;
    }
    .card_title{
        font-size: 0.9rem;
        line-height: 1.2rem;
    }
    .card_description{
        display: none;
    }
    .card_description-desktop{
        color: #171A1FBF;
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
        line-height: 1.5em;
        text-overflow: ellipsis;
        display: -webkit-box;
        overflow: hidden;
        height: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5em;
    }
    .written_by{
        font-size: 0.5.5rem;
    }
    .author_name{
       font-size: 0.6.5rem;
       font-weight: 700;
    }
    .author_image{
        height: 30px;
        width:30px;
    }
    .writter_container{
        margin-left: 6px;
        
    }
    .time-badge-imag-desktop{
        font-size: 12px;
    }
    .author_container-desktop{
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px
        
    }
}
