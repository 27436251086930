#wrapper {
	min-height: 100vh;
	display: flex;
	overflow-y: auto;
	overflow-x: hidden;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.container {
	width: 90%;
	min-height: 100vh;
	flex-grow: 1;
	position: relative;
}
