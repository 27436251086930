/* Container Styling */
.newsletter-container {
	width: 120%;
	margin-left: -10%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #e3e3e3;
	padding-top: 60px;
	padding-bottom: 60px;
	gap: 1rem;
}

/* Text Container */
.newsletter-text-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	text-align: center;
}

.newsletter-text-heading {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.newsletter-logo {
	width: 2.5rem;
	height: 2.5rem;
	background: lightgray 50% / cover no-repeat;
}

.newsletter-logo img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.newsletter-heading {
	color: #101010;
	font-family: "Archivo", sans-serif;
	font-size: 2rem;
	font-weight: 700;
}

.newsletter-text {
	color: #101010;
	font-family: "Archivo", sans-serif;
	font-size: 1.25rem;
	font-weight: 700;
}

/* Email Input and Button */
.newsletter-email-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
}

.newsletter-input-container {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 0.75rem 1rem;
	border-radius: 25px 0 0 25px;
	border: 1px solid #000;
	background: rgba(255, 255, 255, 0.21);
}

.newsletter-email-logo {
	width: 1.5rem;
	height: 1.5rem;
}

.newsletter-input {
	width: 12rem;
	color: rgba(16, 16, 16, 0.7);
	font-family: "DM Sans", sans-serif;
	font-size: 1rem;
	border: none;
	background: transparent;
}

.newsletter-input:focus {
	outline: none;
}

.newsletter-subscribe-button {
	padding: 0.95rem 1rem;
	border-radius: 0 25px 25px 0;
	border: 1px solid #000;
	background: #882a2a;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.newsletter-button-text {
	color: #fff;
	font-family: "DM Sans", sans-serif;
	font-size: 1rem;
	font-weight: 700;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
	.newsletter-container {
		padding: 2rem 1rem;
	}

	.newsletter-heading {
		font-size: 1.75rem;
	}

	.newsletter-text {
		font-size: 1rem;
	}

	.newsletter-input {
		width: 10rem;
	}
	.newsletter-subscribe-button {
		padding: 0.85rem 1rem;
	}
}

@media (max-width: 480px) {
	.newsletter-heading {
		font-size: 1.5rem;
	}

	.newsletter-text {
		font-size: 0.875rem;
	}

	.newsletter-email-logo {
		width: 1.25rem;
		height: 1.25rem;
	}

	.newsletter-input {
		width: 8rem;
		font-size: 0.875rem;
	}

	.newsletter-button-text {
		font-size: 0.875rem;
	}
}
