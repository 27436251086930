.searched_products_container {
	width: 90%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.sp_separater {
	width: 150%;
	height: 1px;
	background-color: black;
	margin-left: -7%;
	margin-top: 3%;
	margin-bottom: 3%;
}

.search_product-page-cards-container {
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	gap: 4%;
	position: relative;
}
